body {
  margin: 0;
  font-family: "-apple-system, BlinkMacSystemFont", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6d8d8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@layer utilities{
  .nav{
    @apply
    relative mx-3 
    hover:text-primary;
    cursor: pointer;
    transition: all;
  }
  .nav::after{
    @apply content-[''] bg-primary h-[3px] w-[0%] left-0 -bottom-[5px] absolute duration-200
  }
  .nav:hover::after{
    @apply w-[100%]
  }
}
@layer base{
  body{
    @apply
    scrollbar-thin scrollbar-thumb-rounded-lg scrollbar-track-transparent scrollbar-thumb-primary
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;